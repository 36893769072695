import { Form, Input, Select } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ROOT from '../../client';
import { PASSWORD_REGEX, READ_ONLY_ROLE, USER_REGEX } from '../../utilities/constants';
import { encryption } from '../../utilities/encryption';
import AddScope from '../AddScope';
import CustomButton from '../Button';
import * as helper from '../helper';
import './style.scss';

const { Option } = Select;

const NewUserForm = ({ tableData, getUsers, setIsLoading, setNewUserForm }) => {
	const [form] = Form.useForm();
	const [createBtnDisable, setCreateBtnDisable] = useState(true);
	const [formRowData, setFormRowData] = useState([
		{
			key: 0,
			field: '',
			fieldType: '',
			operand: '',
			operandOptions: [],
		},
	]);
	const [scopeQueryString, setScopeQueryString] = useState('');
	const [initialScopeData, setInitialScopeData] = useState([]);
	const [isUserExist, setIsUserExist] = useState(false);

	const resetScopeStates = () => {
		setScopeQueryString('');
		setFormRowData([
			{
				key: 0,
				field: '',
				fieldType: '',
				operand: '',
				operandOptions: [],
			},
		]);
		setInitialScopeData([]);
	};

	const onSubmit = async (values) => {
		try {
			const isExist = tableData.find(
				(val) => val.username.toLowerCase() == values.username.toLowerCase(),
			);
			if (isExist) {
				setIsUserExist(true);
				return;
			}
			const encryptPassword = await encryption(values.password);

			setIsLoading(true);

			let payload = {
				username: values.username.trim(),
				password: encryptPassword,
				roles: [values.role],
				attributes: {
					buckets: JSON.stringify(values.buckets),
					scope: scopeQueryString,
					scopeData: scopeQueryString ? JSON.stringify(formRowData) : '',
					updatedAt: new Date(),
					createdAt: new Date(),
				},
			};
			if (values.role == READ_ONLY_ROLE) {
				payload = {
					...payload,
					opendistro_security_roles: ['security_rest_api_access'],
				};
			}
			const res = await axios.post(`${ROOT}/api/create-update-user`, payload);
			if (res) {
				getUsers('create');
				if (values.role == READ_ONLY_ROLE) {
					const users = [values.username.trim()];
					tableData.forEach((val) => {
						if (val.role == values.role) {
							users.push(val.username);
						}
					});
					await axios.post(`${ROOT}/api/rolemapping`, {
						users,
						role: values.role,
					});
				}
				form.resetFields();
				resetScopeStates();
			}
		} catch (error) {
			setNewUserForm(false);
			setIsLoading(false);
			console.error(error);
		}
	};

	const handleOnFormChange = () => {
		try {
			setIsUserExist(false);
			const username = form.getFieldValue('username');
			const password = form.getFieldValue('password');
			const buckets = form.getFieldValue('buckets');
			setCreateBtnDisable(username && password && buckets.length ? false : true);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div className='userForm'>
			<Form
				layout='vertical'
				onFinish={onSubmit}
				form={form}
				requiredMark={false}
				className='deleteDataForm'
				initialValues={{
					username: '',
					password: '',
					role: 'admin',
					buckets: ['fiq-screenshots', 'forwarded', 'review'],
				}}
				onValuesChange={handleOnFormChange}
				autoComplete='off'
			>
				<div className='user_wrapper'>
					<div className='userFormWrapper'>
						<Form.Item
							name='username'
							label={<>Username</>}
							rules={[
								{
									validator(_, value) {
										if (value) {
											value = value.trim();
											let validRegex = helper.regexValidation(USER_REGEX, value);
											if (validRegex) {
												if (value) {
													return Promise.resolve();
												} else {
													setCreateBtnDisable(true);
													return Promise.reject(new Error('Username required!'));
												}
											} else {
												setCreateBtnDisable(true);
												return Promise.reject(
													new Error(
														'The user name must contain from 2 to 50 characters. Valid characters are A-Z, a-z, 0-9, (_)underscore, (-) hyphen and unicode characters.',
													),
												);
											}
										} else {
											setCreateBtnDisable(true);
											return Promise.reject(new Error('Username required!'));
										}
									},
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name='password'
							label={<>Password</>}
							className='cycleField'
							rules={[
								{
									validator(_, value) {
										if (value) {
											value = value.trim();
											let validRegex = helper.regexValidation(PASSWORD_REGEX, value);
											if (validRegex) {
												if (value) {
													return Promise.resolve();
												} else {
													setCreateBtnDisable(true);
													return Promise.reject(new Error('Password required!'));
												}
											} else {
												setCreateBtnDisable(true);
												return Promise.reject(
													new Error(
														'Password should be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',
													),
												);
											}
										} else {
											setCreateBtnDisable(true);
											return Promise.reject(new Error('Password required!'));
										}
									},
								},
							]}
						>
							<Input type='password' />
						</Form.Item>
						<Form.Item name={'role'} label='Role'>
							<Select>
								<Option value={'admin'}>Admin</Option>
								<Option value={READ_ONLY_ROLE}>Read only</Option>
							</Select>
						</Form.Item>
						<AddScope
							setFormRowData={setFormRowData}
							formRowData={formRowData}
							setScopeQueryString={setScopeQueryString}
							scopeQueryString={scopeQueryString}
							initialScopeData={initialScopeData}
							setInitialScopeData={setInitialScopeData}
							parentComponent='userScope'
						/>
						<Form.Item
							className='buckets'
							name={'buckets'}
							label='Select Buckets'
							rules={[
								{
									required: true,
									message: 'Buckets required!',
								},
							]}
						>
							<Select mode='multiple'>
								<Option value={'fiq-screenshots'}>fiq-screenshots</Option>
								<Option value={'forwarded'}>forwarded</Option>
								<Option value={'review'}>review</Option>
							</Select>
						</Form.Item>
					</div>
					{isUserExist && <p style={{ color: 'red' }}>This username is already exist.</p>}

					<div className='userBtnGroup'>
						<CustomButton
							isDisabled={createBtnDisable}
							className='createBtn'
							type='primary'
							htmlType='submit'
						>
							CREATE
						</CustomButton>
						<CustomButton
							onClick={() => {
								setNewUserForm(false);
								form.resetFields();
								resetScopeStates();
							}}
							className='createBtn'
						>
							CANCEL
						</CustomButton>
					</div>
				</div>
			</Form>
		</div>
	);
};

NewUserForm.propTypes = {
	tableData: PropTypes.any,
	getUsers: PropTypes.func,
	setIsLoading: PropTypes.func,
	setNewUserForm: PropTypes.func,
};

export default NewUserForm;
