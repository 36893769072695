import Icon from '@ant-design/icons';
import { Table, Tabs, Tooltip } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ROOT from '../../client';
import {
	ActionBtnIcon,
	EditIcon,
	PreviousArrow,
	RightArrow,
	TrashIcon,
} from '../../utilities/IconSets';
import AddScope from '../AddScope';
import DeleteConfirmation from '../DeleteConfirmationPopup';
import UpdateUser from './updateUser';

const { TabPane } = Tabs;

const itemRender = (_, type, originalElement) => {
	if (type === 'prev') {
		return (
			<a>
				<Icon component={PreviousArrow} />
			</a>
		);
	}
	if (type === 'next') {
		return (
			<a>
				<Icon component={RightArrow} />
			</a>
		);
	}
	return originalElement;
};

const pagination = {
	position: ['bottomCenter'],
	itemRender,
	locale: { items_per_page: '' },
	total: 0,
	defaultPageSize: 10,
	bordered: false,
	hideOnSinglePage: true,
};

const AllUsers = (props) => {
	const { setIsLoading, tableData, getUsers } = props;
	const { userDetails } = useSelector((store) => store.storeProps);
	const [showAction, setShowAction] = useState('');
	const [updateModal, setUpdateModal] = useState({ state: false, record: '' });
	const [formRowData, setFormRowData] = useState([
		{
			key: 0,
			field: '',
			fieldType: '',
			operand: '',
			operandOptions: [],
		},
	]);
	const [scopeQueryString, setScopeQueryString] = useState('');
	const [selectedUserData, setSelectedUserData] = useState('');
	const [initialScopeData, setInitialScopeData] = useState([]);
	const [isScopeFormSubmitted, setIsScopeFormSubmitted] = useState(false);
	const [delModalState, setDelModalState] = useState({ state: false, record: '' });

	const resetScopeStates = () => {
		setScopeQueryString('');
		setFormRowData([
			{
				key: 0,
				field: '',
				fieldType: '',
				operand: '',
				operandOptions: [],
			},
		]);
		setInitialScopeData([]);
		setIsScopeFormSubmitted(false);
	};

	const handleEdit = (values) => {
		if (values.username != 'admin' && values.username != userDetails.username) {
			setUpdateModal({
				state: true,
				record: values,
			});
		}
	};

	const handleUserDelete = (record) => {
		if (record.username != 'admin' && record.username != userDetails.username) {
			setDelModalState({ state: true, record: record?.username });
		}
	};

	const updateUserScope = async (selectedUserData) => {
		try {
			setIsLoading(true);
			const { attributes } = selectedUserData;
			const payload = {
				...selectedUserData,
				roles: [selectedUserData.role],
				attributes: {
					...attributes,
					scope: scopeQueryString,
					scopeData: scopeQueryString ? JSON.stringify(formRowData) : '',
					updatedAt: new Date(),
				},
			};
			const res = await axios.post(`${ROOT}/api/create-update-user`, payload);
			if (res) {
				getUsers('update');
				resetScopeStates();
			}
		} catch (error) {
			resetScopeStates();
			setIsLoading(false);
			console.error(error);
		}
	};

	useEffect(() => {
		if (isScopeFormSubmitted && selectedUserData?.attributes?.scope != scopeQueryString) {
			updateUserScope(selectedUserData);
		} else {
			resetScopeStates();
		}
	}, [isScopeFormSubmitted]);

	const handleEditScope = (username) => {
		const userData = tableData.find((val) => val.username == username);
		let formInitialValues = [];
		const { attributes } = userData;
		if (attributes.scopeData) {
			const scopeData = JSON.parse(attributes.scopeData);
			formInitialValues = scopeData.map((val) => {
				return {
					field: val.field,
					operand: val.operand,
					value: val.value,
					operator: val.operator == 'AND' ? true : false,
				};
			});
			setFormRowData(scopeData);
			setInitialScopeData(formInitialValues);
		} else {
			setFormRowData([
				{
					key: 0,
					field: '',
					fieldType: '',
					operand: '',
					operandOptions: [],
				},
			]);
			setInitialScopeData([]);
		}

		setSelectedUserData(userData);
		setScopeQueryString(userData.scope);
	};

	const handleDelete = async () => {
		try {
			setIsLoading(true);
			const payload = {
				username: delModalState.record,
			};
			const res = await axios.post(`${ROOT}/api/delete-user`, payload);
			if (res) {
				setDelModalState({ state: false, record: '' });
				getUsers('delete');
			}
		} catch (error) {
			setIsLoading(false);
			console.error(error);
		}
	};

	const columns = [
		{
			title: 'Username',
			dataIndex: 'username',
			key: 'username',
			render: (username) => {
				return (
					<p className='usernameCol'>
						{username} {userDetails.username == username && <span>current</span>}
						{username == 'admin' && <span>default</span>}
					</p>
				);
			},
		},
		{
			title: 'Role',
			dataIndex: 'role',
			key: 'role',
		},
		{
			title: 'Access Scope',
			key: 'scope',
			width: 500,
			render: (record) => {
				return (
					<>
						<div className='userScopeSection'>
							<p>{record.scope}</p>
							{record.username != 'admin' && record.username != userDetails.username && (
								<>
									<Tooltip placement='top' title='Edit scope'>
										<Icon component={EditIcon} onClick={() => handleEditScope(record.username)} />
									</Tooltip>
								</>
							)}
						</div>
						{record.username != userDetails.username && (
							<div style={{ visibility: 'hidden', height: '0px' }}>
								<AddScope
									setFormRowData={setFormRowData}
									formRowData={formRowData}
									setScopeQueryString={setScopeQueryString}
									scopeQueryString={scopeQueryString}
									initialScopeData={initialScopeData}
									setInitialScopeData={setInitialScopeData}
									parentComponent='userScope'
									currentRowScope={record.scope}
									setIsScopeFormSubmitted={setIsScopeFormSubmitted}
								/>
							</div>
						)}
					</>
				);
			},
		},
		{
			title: 'Buckets',
			dataIndex: 'buckets',
			key: 'buckets',
		},
		{
			title: '',
			key: 'actions',
			width: '10px',
			render: (record) => {
				return (
					<div
						className={`action-icon-box actionIcons`}
						onMouseEnter={() => setShowAction(record.username)}
						onMouseLeave={() => setShowAction('')}
					>
						<Icon component={ActionBtnIcon} />
						<div
							className={`action-icon-box-abs no-border ${
								record.username !== showAction ? 'hide' : 'visible'
							} `}
						>
							<Tooltip placement='top' title='Edit'>
								<Icon
									className={
										record.username == 'admin' || record.username == userDetails.username
											? 'disable'
											: ''
									}
									component={EditIcon}
									onClick={() => handleEdit(record)}
								/>
							</Tooltip>
							<Tooltip placement='top' title='Delete'>
								<Icon
									className={
										record.username == 'admin' || record.username == userDetails.username
											? 'disable'
											: ''
									}
									component={TrashIcon}
									onClick={() => handleUserDelete(record)}
								/>
							</Tooltip>
						</div>
					</div>
				);
			},
		},
	];

	return (
		<>
			<Tabs defaultActiveKey='1' className='peg_tabs'>
				<TabPane tab='All Users' key='1'>
					<Table
						className='policyStatusTable'
						columns={columns}
						dataSource={tableData}
						bordered={true}
						defaultPageSize={10}
						pagination={pagination}
					/>
				</TabPane>
			</Tabs>
			<UpdateUser
				setUpdateModal={setUpdateModal}
				updateModal={updateModal}
				setIsLoading={setIsLoading}
				getUsers={getUsers}
				tableData={tableData}
			/>
			<DeleteConfirmation
				deleteAction={handleDelete}
				modalState={delModalState}
				setModalState={setDelModalState}
				text={'Do you really want to delete this user?'}
			/>
		</>
	);
};

AllUsers.propTypes = {
	isLoading: PropTypes.bool,
	setIsLoading: PropTypes.func,
	tableData: PropTypes.array,
	getUsers: PropTypes.func,
};

export default AllUsers;
