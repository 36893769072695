import {
	CaretDownFilled,
	FileTextOutlined,
	FileZipOutlined,
	FilterOutlined,
	PicLeftOutlined,
	PictureOutlined,
} from '@ant-design/icons';
import { Badge, Button, Checkbox, Col, Popover, Row, Switch, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	saveFilterFlag,
	saveFilters,
	saveGraphVisibility,
	saveQueryStack,
	setView,
} from '../../store/actions';
import { REDACTION_VIEW, operators } from '../../utilities/constants';
import CustomButton from '../Button';
import DateTimePicker from '../DateTimePicker';
import DeleteModal from '../DeleteQueryComponent';
import DeleteProgressModal from '../DeleteQueryComponent/ProgressModal';
import ExportImages from '../Export';
import ExportLogs from '../ExportLogs';
import ExportQuery from '../ExportQuery/exportQuery';
import AddFilter from '../FilterPopUp/AddFilter';
import EditFilter from '../FilterPopUp/editFilter';
import UpdateSignature from '../FilterPopUp/updateSignature';
import ImportFile from '../ImportQuery/importFile';
import ImportQuery from '../ImportQuery/importQuery';
import { Spinner } from '../Loader';
import QueryStackComponent from '../QueryStack';
import SourceInfo from '../SourceInfo';
import UserQueryStack from '../UserScopeQuery';
import './search.scss';

const carouselOptions = {
	carouselView: 'Carousel View',
	masked: 'Carousel View',
	realTimeRedaction: 'Redaction View',
};

const Search = ({
	queryData,
	setQueryData,
	setIsLoading,
	isLoading,
	setEnableExport,
	setSelectedOption,
	selectedOption,
	setAreaQuery,
	setGraphdata,
	setAnnotations,
	enableExport,
	setGraphVisible,
	setGraphEvent,
	graphEvent,
	isDataLoading,
	setFinalQuery,
	finalQuery,
	setMinioClient,
	minioClient,
	setMapping,
}) => {
	const dispatch = useDispatch();
	const {
		selectedBucket,
		view,
		maskedBucket,
		queryStack,
		filters,
		graphVisibility,
		envars,
		showFilterFlag,
		isDeleteFilterFlag,
		isDeleteInProgressFlag,
		noRecordsAvailable,
		isDeleteModeOn,
		userDetails,
	} = useSelector((store) => store.storeProps);
	const { deployEnv } = envars;
	const [lowerRange, setLowerRange] = useState('');
	const [higherRange, setHigherRange] = useState('');
	const [filterValue, setFilterValue] = useState([]);
	const [addFilter, setAddFilter] = useState(false);
	const [isFieldVisible, setFieldsVisible] = useState(false);
	const [isExportVisible, setExportVisible] = useState(false);
	const [queryString, setQueryString] = useState(filters.length ? filters : []);
	const [activeAll, setActiveAll] = useState(true);
	const [isValueMatch, setIsValueMatch] = useState(false);
	const [importFile, setImportFile] = useState(false);
	const [editLoader, setEditLoader] = useState(false);
	const [isLogsVisible, setLogsVisible] = useState(false);
	const [exportImageDisable, setExportImageDisable] = useState(false);
	const [exportLogDisable, setExportLogDisable] = useState(false);
	const [defaultScope, setDefaultScope] = useState([]);

	let checkedFieldsCount = selectedOption.filter((val) => {
		return val.isChecked;
	});

	let checkedFiltersCount = queryStack.filter((val) => {
		return val.isChecked;
	});

	useEffect(() => {
		try {
			let qs = '';
			let finalQ = '';
			let filtersArr = [];
			let andFilters = [];
			let scopeData = [];
			let qsArray = [...queryStack];
			if (userDetails?.attributes?.scopeData) {
				scopeData = JSON.parse(userDetails.attributes.scopeData);
				const defaultFilters = scopeData.map((val) => {
					const op = operators.find((i) => i.type == val.operand);
					return {
						query: `${val.field} ${op.symbol} ${val.value}`,
						isChecked: true,
						field: val.field,
						fieldValue: val.value,
						operand: val.operator,
						group: false,
						allowOperand: true,
						braces: '',
						operator: op.symbol,
					};
				});
				qsArray = [...defaultFilters, ...queryStack];
				setDefaultScope(defaultFilters);
			}

			if (qsArray.length) {
				qsArray.forEach((val) => {
					if (val.isChecked === true) {
						if (val.operand === 'OR') {
							qs = qs ? `${qs} AND ${val.query})` : `(${val.query})`;
							andFilters.push({
								key: val.field,
								value: val.fieldValue,
								operator: val.operator,
								operand: val.operand,
							});
							filtersArr.push(andFilters);
							andFilters = [];
							finalQ = finalQ ? `${finalQ} OR ${qs}` : qs;
							qs = '';
						} else {
							qs = qs ? `${qs} AND ${val.query}` : `(${val.query}`;
							andFilters.push({
								key: val.field,
								value: val.fieldValue,
								operator: val.operator,
								operand: val.operand,
							});
						}
					}
				});
				if (andFilters.length) {
					filtersArr.push(andFilters);
				}
				for (let i = 0; i < queryStack.length; i++) {
					if (queryStack[i].isChecked === true) {
						setActiveAll(true);
					} else {
						setActiveAll(false);
						break;
					}
				}
			}
			finalQ = finalQ ? `${finalQ} ${qs ? `OR ${qs})` : ''}` : `${qs ? `${qs})` : ''}`;
			setFinalQuery(finalQ);
			if (!finalQ) {
				setIsVisible(false);
				setUpdateSignatureFlag(false);
				setImportFile(false);
			}
			setQueryString(filtersArr);
			dispatch(saveFilters(filtersArr));
		} catch (error) {
			console.error(error);
		}
		// eslint-disable-next-line
	}, [queryStack, userDetails]);

	const [isVisible, setIsVisible] = useState(false);
	const showFinalQuery = () => {
		setIsVisible(isVisible ? false : true);
	};

	const onCheckedAll = (e) => {
		try {
			if (e && e.target?.checked) {
				updateQStack(true);
			} else {
				updateQStack(false);
			}
			setActiveAll(e.target.checked);
		} catch (error) {
			console.error(error);
		}
	};
	const updateQStack = (e) => {
		try {
			if (queryStack.length) {
				const newStack = queryStack.map((val) => {
					if (typeof e === 'boolean') {
						val = { ...val, isChecked: e };
					}
					return val;
				});
				if (newStack?.length && typeof e === 'boolean') {
					newStack.forEach((val, i) => {
						if (e) {
							if (val.operand === 'OR' && i > 0) {
								newStack[i].group = true;
								newStack[i].allowOperand = true;
							} else {
								if (val.braces !== '') {
									newStack[i].group = true;
									newStack[i].allowOperand = false;
								} else {
									newStack[i].group = false;
									newStack[i].allowOperand = true;
								}
							}
						} else {
							if (val.operand === 'OR' && i > 0) {
								newStack[i].allowOperand = false;
							} else {
								if (val.braces !== '') {
									newStack[i].group = false;
								} else if (i > 0) {
									newStack[i].allowOperand = false;
								}
							}
						}
					});
				}
				dispatch(saveQueryStack(newStack));
			}
		} catch (error) {
			console.error(error);
		}
	};

	const [editFilter, setEditFilter] = useState(false);
	const [queryDetailArr, setQueryDetailArr] = useState([]);
	const [editIndex, setEditIndex] = useState('');
	const [editInitialVals, setEditInitialVals] = useState('');
	const [updateSignatureFlag, setUpdateSignatureFlag] = useState(false);
	const handleCancel = () => {
		try {
			setEditIndex('');
			setValidaton({ isValidate: true, message: '' });
			setEditFilter(false);
			setUpdateSignatureFlag(false);
			setImportFile(false);
			setEditInitialVals('');
		} catch (error) {
			console.error(error);
		}
	};
	const handleUpdateSignature = () => {
		try {
			setUpdateSignatureFlag(true);
			setAddFilter(false);
			setEditFilter(false);
			setImportFile(false);
		} catch (error) {
			console.error(error);
		}
	};
	const [validation, setValidaton] = useState({ isValidate: true, message: '' });

	const handleAddFilter = () => {
		try {
			setAddFilter(true);
			setEditFilter(false);
			setUpdateSignatureFlag(false);
			setImportFile(false);
		} catch (error) {
			console.error(error);
		}
	};

	const renderFields = () => {
		return <SourceInfo selectedOption={selectedOption} setSelectedOption={setSelectedOption} />;
	};

	const popoverVisible = () => {
		dispatch(saveFilterFlag({ showFilterFlag: !showFilterFlag }));
		if (!isFieldVisible) {
			setAddFilter(false);
			setEditFilter(false);
			setUpdateSignatureFlag(false);
			setImportFile(false);
		}
	};
	const filterWrapper = useRef(null);
	const filterSection = useRef(null);
	useEffect(() => {
		if (addFilter || editFilter || updateSignatureFlag || importFile) {
			handleScroll();
		}
	}, [addFilter, editFilter, updateSignatureFlag, importFile]);
	const handleScroll = () => {
		try {
			if (filterWrapper && filterSection) {
				const popupDiv = filterWrapper.current;
				const formDiv = filterSection.current;
				if (formDiv.offsetTop && formDiv.offsetTop > 300) {
					popupDiv.scrollTo(0, popupDiv.scrollHeight);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	const renderFilter = () => {
		return (
			<div className='pop-filters' ref={filterWrapper}>
				{editLoader && (
					<>
						<div className='editLoaderClass'>
							<div className='loaderIcon'>
								<Spinner />
								<br></br>Please wait
							</div>
						</div>
					</>
				)}
				<Row>
					<Col span={24}>
						<strong>Filters</strong>
					</Col>
				</Row>
				{isDataLoading ? (
					<>
						<div className='noFilters'>
							<Spinner />
							Please wait images is still loading in carousel...
						</div>
					</>
				) : (
					<>
						<Row className={`${queryStack.length > 0 ? 'query-wrapper' : ''}`}>
							<Col span={24}>
								<UserQueryStack />
								{queryStack.length > 0 ? (
									<QueryStackComponent
										queryStack={queryStack}
										setEditIndex={setEditIndex}
										setQueryDetailArr={setQueryDetailArr}
										setEditFilter={setEditFilter}
										setAddFilter={setAddFilter}
										setUpdateSignatureFlag={setUpdateSignatureFlag}
										setEditInitialVals={setEditInitialVals}
										setIsValueMatch={setIsValueMatch}
										setImportFile={setImportFile}
										setEditLoader={setEditLoader}
									/>
								) : (
									<div className='noFilters'>
										<img src='./no-filter.png' className='no-filetrs-logo' alt='FIQ' />
										No Filter Added
									</div>
								)}
							</Col>
						</Row>
						<Row className={'query-content-wrapper'}>
							<Col span={24}>
								<div className={`${isVisible ? 'showQuery' : 'hideQuery'}`}>
									<div className={'finalQuery'}>
										<p>{finalQuery}</p>
									</div>
								</div>
							</Col>
						</Row>
						<Row className={'filters-footer'}>
							<Col span={7}>
								{queryStack.length > 0 && (
									<ul className='queryList'>
										<li className='queryButton'>
											<CustomButton
												isDisabled={finalQuery ? false : true}
												onClick={showFinalQuery}
												className='showHideQuery'
											>
												{isVisible ? 'Hide Query' : 'Show Query'}
											</CustomButton>
										</li>
										{queryStack.length > 1 && (
											<li className='checkedAllButton'>
												<Checkbox id='filtersCheckbox' checked={activeAll} onChange={onCheckedAll}>
													{activeAll ? 'Uncheck All' : 'Check All'}
												</Checkbox>
											</li>
										)}
									</ul>
								)}
							</Col>
							<Col span={17} className='add-filter-btn'>
								<ImportQuery
									setImportFile={setImportFile}
									setUpdateSignatureFlag={setUpdateSignatureFlag}
									setAddFilter={setAddFilter}
									setEditFilter={setEditFilter}
								/>
								{queryStack.length > 0 && (
									<ExportQuery
										queryStack={queryStack}
										enableExport={enableExport}
										finalQuery={finalQuery}
										minioClient={minioClient}
									/>
								)}
								{queryStack.length > 0 && !isDeleteFilterFlag && (
									<CustomButton
										id='updateSignature'
										isDisabled={finalQuery ? (enableExport ? false : true) : true}
										onClick={handleUpdateSignature}
									>
										Update Signature
									</CustomButton>
								)}
								<CustomButton type='primary' className='btnAddFilter' onClick={handleAddFilter}>
									Add Filter
								</CustomButton>
							</Col>
						</Row>
						<Row ref={filterSection}>
							<Col span={24}>
								{addFilter && (
									<AddFilter
										lowerRange={lowerRange}
										setLowerRange={setLowerRange}
										higherRange={higherRange}
										setHigherRange={setHigherRange}
										setFilterValue={setFilterValue}
										filterValue={filterValue}
										isLoading={isLoading}
										queryStack={queryStack}
										setAddFilter={setAddFilter}
										defaultScope={defaultScope}
									/>
								)}
								{editFilter && (
									<EditFilter
										queryDetailArr={queryDetailArr}
										editInitialVals={editInitialVals}
										validation={validation}
										queryStack={queryStack}
										editIndex={editIndex}
										setValidaton={setValidaton}
										handleCancel={handleCancel}
										isValueMatch={isValueMatch}
										setIsValueMatch={setIsValueMatch}
										defaultScope={defaultScope}
									/>
								)}

								{updateSignatureFlag && (
									<UpdateSignature
										handleCancel={handleCancel}
										setUpdateSignatureFlag={setUpdateSignatureFlag}
									/>
								)}

								{importFile && (
									<ImportFile
										queryStack={queryStack}
										handleCancel={handleCancel}
										setImportFile={setImportFile}
									/>
								)}
							</Col>
						</Row>
					</>
				)}
			</div>
		);
	};

	const renderExport = () => {
		return (
			<ExportImages
				queryData={queryData}
				enableExport={enableExport}
				setExportLogDisable={setExportLogDisable}
				minioClient={minioClient}
			/>
		);
	};

	const renderExportLogs = () => {
		return <ExportLogs setExportImageDisable={setExportImageDisable} />;
	};

	const disablePopover = queryData.length === 0 ? 'disable-popup' : '';
	const noPointerEvents = queryData.length === 0 ? 'pointer-events-none' : '';
	const [viewFlag, setViewFlag] = useState(false);

	const renderViews = () => {
		return (
			<ul className='renderViewList'>
				<li
					className={view === 'carouselView' || view === 'masked' ? 'active' : ''}
					onClick={() => {
						dispatch(setView(maskedBucket ? 'masked' : 'carouselView'));
						setViewFlag(!viewFlag);
					}}
				>
					<span></span>Carousel View
				</li>
				{maskedBucket && (
					<>
						{(maskedBucket.bucketName === 'forwarded' || maskedBucket.bucketName === 'review') && (
							<>
								{selectedBucket?.bucketName === 'fiq-screenshots' && deployEnv !== 'aws' && (
									<li
										className={view === REDACTION_VIEW ? 'active' : ''}
										onClick={() => {
											dispatch(setView(REDACTION_VIEW));
											setViewFlag(!viewFlag);
										}}
									>
										<span></span> Real Time Redaction
									</li>
								)}
							</>
						)}
					</>
				)}
			</ul>
		);
	};

	const handleGraph = (checked) => {
		dispatch(saveGraphVisibility(checked));
		setGraphVisible(checked);
	};

	return (
		<>
			{isDeleteInProgressFlag && <DeleteProgressModal onCheckedAll={onCheckedAll} />}
			{!noRecordsAvailable && isDeleteModeOn && (
				<DeleteModal
					queryString={queryString}
					queryStack={queryStack}
					setGraphdata={setGraphdata}
					setQueryData={setQueryData}
					setSelectedOption={setSelectedOption}
					setLowerRange={setLowerRange}
					setHigherRange={setHigherRange}
					setFilterValue={setFilterValue}
					setAreaQuery={setAreaQuery}
					setAnnotations={setAnnotations}
					setGraphEvent={setGraphEvent}
					graphEvent={graphEvent}
					setIsLoading={setIsLoading}
					isLoading={isLoading}
					setEnableExport={setEnableExport}
					setMinioClient={setMinioClient}
					lowerRange={lowerRange}
					higherRange={higherRange}
					filterValue={filterValue}
					setAddFilter={setAddFilter}
					setMapping={setMapping}
				/>
			)}
			<Row className='search-header'>
				{selectedBucket?.bucketName && (
					<Col flex='1 1 0' className='datepicker'>
						<div className='overflow-hidden hide-text'>
							<DateTimePicker
								isDisabled={isDeleteModeOn}
								queryString={queryString}
								queryStack={queryStack}
								setGraphdata={setGraphdata}
								setQueryData={setQueryData}
								setSelectedOption={setSelectedOption}
								setLowerRange={setLowerRange}
								setHigherRange={setHigherRange}
								setFilterValue={setFilterValue}
								setAreaQuery={setAreaQuery}
								setAnnotations={setAnnotations}
								setGraphEvent={setGraphEvent}
								graphEvent={graphEvent}
								setIsLoading={setIsLoading}
								isLoading={isLoading}
								setEnableExport={setEnableExport}
								setMinioClient={setMinioClient}
								setMapping={setMapping}
							/>
						</div>
					</Col>
				)}

				{!noRecordsAvailable && (
					<>
						<Col
							flex='auto'
							className={`align-content ${disablePopover} ${
								isFieldVisible ? 'highlight-field' : ''
							}`}
						>
							<Popover
								content={renderFields()}
								trigger='click'
								visible={isFieldVisible}
								placement='bottom'
								onVisibleChange={() => {
									setFieldsVisible(!isFieldVisible);
								}}
								className={`overflow-hidden ${noPointerEvents} ${isFieldVisible ? 'active' : ''}`}
							>
								<PicLeftOutlined />
								Configure Field
								<Badge className='filters-badge' count={checkedFieldsCount.length} />
								<CaretDownFilled />
							</Popover>
						</Col>
						<Col
							flex='auto'
							className={`align-content ${
								showFilterFlag && !isDeleteModeOn ? 'highlight-field' : ''
							}`}
						>
							<Popover
								content={renderFilter()}
								trigger='click'
								visible={!isDeleteModeOn && showFilterFlag}
								placement='bottom'
								onVisibleChange={popoverVisible}
								className={`filtersPop overflow-hidden 
								${showFilterFlag ? 'active' : ''} 
								${isDeleteModeOn && 'disable-popup'}`}
								onClick={() => {
									setMapping(true);
								}}
							>
								<FilterOutlined />
								Filters
								{!isDeleteModeOn && (
									<Badge className='filters-badge' count={checkedFiltersCount.length} />
								)}
								<CaretDownFilled />
							</Popover>
						</Col>
						<Col flex='auto' className={`align-content ${viewFlag ? 'highlight-field' : ''}`}>
							<Popover
								content={renderViews()}
								trigger='click'
								visible={viewFlag}
								placement='bottom'
								onVisibleChange={() => setViewFlag(!viewFlag)}
								className={`overflow-hidden ${viewFlag ? 'active' : ''}`}
							>
								<FileTextOutlined />
								{view ? carouselOptions[view] : 'Carousel View'}
								<CaretDownFilled />
							</Popover>
						</Col>
						<Col flex='auto' className={`align-content graphOption ${disablePopover}`}>
							<div className={`overflow-hidden  ${noPointerEvents}`}>
								Graph
								<Switch
									size='small'
									checkedChildren='Hide'
									unCheckedChildren='Show'
									defaultChecked={graphVisibility}
									className='graph-slide'
									onClick={(checked) => handleGraph(checked)}
								/>
							</div>
						</Col>
					</>
				)}
				<Col flex='auto' className={`align-content flex-end exportOptions ${disablePopover}`}>
					{!noRecordsAvailable && (
						<>
							<Popover
								content={renderExport()}
								trigger='click'
								visible={isExportVisible}
								placement='bottomRight'
								onVisibleChange={() => {
									if (!exportImageDisable) {
										setExportVisible(!isExportVisible);
									}
								}}
								className={`overflow-hidden  ${noPointerEvents} ${isExportVisible ? 'active' : ''}`}
							>
								<Tooltip placement='bottom' arrowPointAtCenter title='Export Images'>
									<Button
										disabled={exportImageDisable}
										className='btnExport'
										type='primary'
										size='large'
										icon={<PictureOutlined />}
									/>
								</Tooltip>
							</Popover>
						</>
					)}
					{deployEnv === 'aws' && (
						<Popover
							content={renderExportLogs()}
							trigger='click'
							visible={isLogsVisible}
							placement='bottomRight'
							onVisibleChange={() => {
								if (!exportLogDisable) {
									setLogsVisible(!isLogsVisible);
								}
							}}
							className={`overflow-hidden disable-popup ${isLogsVisible ? 'active' : ''}`}
						>
							<Tooltip placement='bottom' arrowPointAtCenter title='Export Logs'>
								<Button
									className='btnExport'
									type='primary'
									size='large'
									icon={<FileZipOutlined />}
									disabled={exportLogDisable}
								/>
							</Tooltip>
						</Popover>
					)}
				</Col>
			</Row>
		</>
	);
};

Search.propTypes = {
	queryData: PropTypes.array,
	setQueryData: PropTypes.func,
	setIsLoading: PropTypes.func,
	isLoading: PropTypes.bool,
	setEnableExport: PropTypes.func,
	setSelectedOption: PropTypes.func,
	selectedOption: PropTypes.array,
	setAreaQuery: PropTypes.func,
	setGraphdata: PropTypes.func,
	setAnnotations: PropTypes.func,
	enableExport: PropTypes.bool,
	setGraphVisible: PropTypes.func,
	setGraphEvent: PropTypes.func,
	graphEvent: PropTypes.string,
	isDataLoading: PropTypes.bool,
	setFinalQuery: PropTypes.func,
	finalQuery: PropTypes.string,
	setMinioClient: PropTypes.func,
	minioClient: PropTypes.object,
	setMapping: PropTypes.func,
};

export default Search;
