import { Form, Input, Select } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ROOT from '../../client';
import { PASSWORD_REGEX, READ_ONLY_ROLE } from '../../utilities/constants';
import { encryption } from '../../utilities/encryption';
import CustomButton from '../Button';
import CustomModal from '../Modal';
import * as helper from '../helper';
import './style.scss';

const { Option } = Select;

const UpdateUser = ({ updateModal, setUpdateModal, setIsLoading, getUsers, tableData }) => {
	const [form] = Form.useForm();
	const [isValueChanged, setIsValueChanged] = useState(false);

	const onSubmit = async (values) => {
		try {
			setIsLoading(true);
			const { record } = updateModal;
			const { attributes } = record;
			let payload = {
				username: record.username,
				roles: [values.role],
				attributes: {
					...attributes,
					buckets: JSON.stringify(values?.buckets),
					updatedAt: new Date(),
				},
			};
			if (values?.password) {
				const encryptPassword = await encryption(values.password);
				payload = {
					...payload,
					password: encryptPassword,
				};
			}
			if (values.role == READ_ONLY_ROLE) {
				payload = {
					...payload,
					opendistro_security_roles: ['security_rest_api_access'],
				};
			}
			setUpdateModal({ ...updateModal, state: false });
			const res = await axios.post(`${ROOT}/api/create-update-user`, payload);
			if (res) {
				form.resetFields();
				getUsers('update');
				if (values.role == READ_ONLY_ROLE) {
					const users = [record.username];
					tableData.forEach((val) => {
						if (val.role == values.role) {
							users.push(val.username);
						}
					});
					await axios.post(`${ROOT}/api/rolemapping`, {
						users,
						role: values.role,
					});
				}
			}
		} catch (error) {
			setIsLoading(false);
			console.error(error);
		}
	};

	useEffect(() => {
		if (updateModal?.state && updateModal?.record) {
			if (isValueChanged) {
				setIsValueChanged(false);
			}
			const record = updateModal.record;
			form.setFieldsValue({
				role: record?.role,
				buckets: record?.attributes?.buckets ? JSON.parse(record.attributes.buckets) : [],
			});
		}
	}, [updateModal]);

	return (
		<>
			<CustomModal
				isOpen={updateModal.state}
				className='successModal updateUserModal'
				footer={[]}
				width={420}
			>
				<div className='updateUserWrapper'>
					<h3>Edit User</h3>
					<Form
						layout='vertical'
						onFinish={onSubmit}
						form={form}
						requiredMark={false}
						className='deleteDataForm'
						onValuesChange={() => setIsValueChanged(true)}
					>
						<Form.Item
							name='password'
							label={<>Password</>}
							className='cycleField'
							rules={[
								{
									validator(_, value) {
										if (value) {
											value = value.trim();
											let validRegex = helper.regexValidation(PASSWORD_REGEX, value);
											if (validRegex) {
												if (value) {
													setIsValueChanged(true);
													return Promise.resolve();
												}
											} else {
												setIsValueChanged(false);
												return Promise.reject(
													new Error(
														'Password should be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.',
													),
												);
											}
										} else {
											return Promise.resolve();
										}
									},
								},
							]}
						>
							<Input type='password' />
						</Form.Item>
						<Form.Item name={'role'} label='Role'>
							<Select>
								<Option value={'admin'}>Admin</Option>
								<Option value={READ_ONLY_ROLE}>Read only</Option>
							</Select>
						</Form.Item>
						<Form.Item
							name={'buckets'}
							label='Select Buckets'
							rules={[
								{
									required: true,
									message: 'Required field!',
								},
							]}
						>
							<Select mode='multiple'>
								<Option value={'fiq-screenshots'}>fiq-screenshots</Option>
								<Option value={'forwarded'}>forwarded</Option>
								<Option value={'review'}>review</Option>
							</Select>
						</Form.Item>
						<div className='updateUserButtons'>
							<CustomButton
								onClick={() => {
									setUpdateModal({ state: false, record: '' });
									form.resetFields();
								}}
								key='btnClose'
								id='btnClose'
							>
								Cancel
							</CustomButton>

							<CustomButton
								className='r-commit'
								htmlType='submit'
								type='primary'
								key='btndownload'
								isDisabled={isValueChanged ? false : true}
							>
								Save
							</CustomButton>
						</div>
					</Form>
				</div>
			</CustomModal>
		</>
	);
};

UpdateUser.propTypes = {
	updateModal: PropTypes.object,
	setUpdateModal: PropTypes.func,
	setIsLoading: PropTypes.func,
	getUsers: PropTypes.func,
	tableData: PropTypes.any,
};

export default UpdateUser;
